<template>
  <div style="padding: 30px 30px 0 0;">
    <NForm
      ref="formRef"
      label-placement="left"
      label-width="120px"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="选项答案：" show-require-mark>
        <div>
          <NFormItem
            v-for="(item, index) in formValue.options"
            :key="item.key"
            :rule="formRules.option"
            :path="`options[${index}].label`"
          >
            <NInput
              style="width: 300px;"
              clearable
              v-model:value="item.label"
            />
            <NIcon size="22" style="margin: 0 20px 0 10px;" @click="selectAns(item.value)">
              <template v-if="formType === formItemTypeVal.SELECT || formType === formItemTypeVal.RADIO_GROUP">
                <RadioButtonCheckedFilled
                  :color="themeSettings.primaryColor"
                  v-if="formValue.answer === item.value"
                />
                <RadioButtonUncheckedFilled
                  color="#ccc"
                  v-else
                />
              </template>
              <template v-else-if="formType === formItemTypeVal.CHECKBOX_GROUP">
                <CheckBoxRound
                  :color="themeSettings.primaryColor"
                  v-if="formValue.answer.includes(item.value)"
                />
                <CheckBoxOutlineBlankFilled
                  color="#ccc"
                  v-else
                />
              </template>
            </NIcon>
            <NButton
              v-if="formValue.options.length > 2"
              type="error"
              text
              @click="delOption(index, item)"
            >删除</NButton>
          </NFormItem>
          <NButton type="primary" text @click="addOption">添加选项答案</NButton>
        </div>
      </NFormItem>
      <!--
      <SkillsFormItem
        :skills="formValue.skills"
        :autoInitSkillList="false"
        :initSkillList="skillList"
      />
      -->
    </NForm>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { NForm, NFormItem, NInput, NSelect, NInputNumber, NButton, NIcon, useMessage } from 'naive-ui';
  import { RadioButtonCheckedFilled, RadioButtonUncheckedFilled } from '@vicons/material';
  import { CheckBoxRound, CheckBoxOutlineBlankFilled } from '@vicons/material';

  // import SkillsFormItem from '../SkillsFormItem/SkillsFormItem.vue'

  import themeSettings from '@/settings/theme-settings.js';
  import { formItemTypeVal, generateAnsOption, generateSkillItem } from '../../generate-data.js';

  const props = defineProps({
    formType: {
      required: true,
      type: Number
    },
    skillList: {
      type: Array,
      default: () => []
    }
  });

  const message = useMessage();

  const formRef = ref(null);
  const formValue = reactive({
    answer: null,
    options: [],
    skills: []
  });
  const formRules = {
    option: {
      required: true,
      trigger: 'blur',
      message: '请输入选项'
    }
  };
  function addOption() {
    const options = formValue.options;
    formValue.options.push(
      generateAnsOption('isCreate', {
        label: '',
        value: options[options.length - 1].value + 1
      })
    )
  }
  function selectAns(value) {
    switch (props.formType) {
      case formItemTypeVal.SELECT:
      case formItemTypeVal.RADIO_GROUP:
        formValue.answer = value;
        break;
      case formItemTypeVal.CHECKBOX_GROUP:
        const index = formValue.answer.indexOf(value);
        if (index !== -1) {
          formValue.answer.splice(index, 1);
        } else {
          formValue.answer.push(value);
        }
        break;
    }
  }
  function delOption(index, { value }) {
    formValue.answer === value && (formValue.answer = null);
    formValue.options.splice(index, 1);
  }
  function addSkill() {
    formValue.skills.push(generateSkillItem('isCreate'));
  }

  defineExpose({
    setFormValue(data) {
      Object.keys(formValue).forEach(key => {
        formValue[key] = data[key];
      });
      if (props.formType === formItemTypeVal.CHECKBOX_GROUP) {
        formValue.answer || (formValue.answer = []);
      }
      formValue.skills.length === 0 && addSkill();
    },
    getFormValue() {
      return new Promise((resolve, reject) => {
        formRef.value.validate(errors => {
          if (errors) {
            reject();
          } else {
            let ansEmpty = true;
            switch (props.formType) {
              case formItemTypeVal.SELECT:
              case formItemTypeVal.RADIO_GROUP:
                if (formValue.answer || formValue.answer === 0) {
                  ansEmpty = false;
                }
                break;
              case formItemTypeVal.CHECKBOX_GROUP:
                if (formValue.answer.length > 0) {
                  ansEmpty = false;
                }
                break;
            }

            if (ansEmpty) {
              message.error('请选择正确答案');
              reject();
            } else {
              resolve(formValue);
            }
          }
        });
      });
    }
  });
</script>