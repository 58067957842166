<template>
  <div>
    <NModal
      v-model:show="modalShow"
      preset="card"
      :segmented="{ footer: 'hard' }"
      title="批量新增习题"
      style="width: 600px;"
      :mask-closable="false"
      @after-leave="clearModal"
    >
      <template #default>
        <NForm
          ref="formRef"
          label-placement="left"
          :label-width="85"
          require-mark-placement="left"
          style="padding: 20px 30px 0 0;"
          :model="formValue"
          :rules="formRules"
        >
          <NFormItem label="习题：" path="file">
            <div>
              <NSpace>
                <NButton
                  :disabled="!!formValue.file"
                  @click="handleClickUpload"
                >上传文件</NButton>
                <NButton
                  tag="a"
                  target="_blank"
                  :href="batImportTemplateUrl"
                >模板下载</NButton>
              </NSpace>
              <div style="margin-top: -30px; overflow-y: hidden;">
                <NUpload
                  ref="uploadRef"
                  :default-upload="false"
                  :max="1"
                  :accept="permitFileExtsWithDot.join(',')"
                  @before-upload="beforeUpload"
                  @change="handleChange"
                />
              </div>
            </div>
          </NFormItem>
          <NFormItem v-if="batImportType === 'toExercises'" label="标签：" path="labelIds">
            <NSelect
              multiple
              clearable
              :options="labelOptions"
              v-model:value="formValue.labelIds"
            />
          </NFormItem>
          <div style="margin-top: 20px; padding-left: 30px;">
            <div>支持“<span :style="{ color: themeSettings.appThemeColor }">单项选择题、多项选择题、判断题、填空题、开发性主观题</span>”题型的导入</div>
            <div>第一步：下载Excel习题模板，将所准备的试题填入模板文件，并保存；</div>
            <div>第二步：点击“<span :style="{ color: themeSettings.appThemeColor }">上传文件</span>”，选择保存好的习题文件，完成习题导入。</div>
          </div>
        </NForm>
      </template>
      <template #footer>
        <NSpace justify="end">
          <NButton @click="closeModal">取消</NButton>
          <NButton type="primary" @click="handleConfirm">确定</NButton>
        </NSpace>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { getLabelList } from '@/api/label.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import themeSettings from '@/settings/theme-settings.js';
  import env from '@/settings/env.js';
  import { getToken } from '@/utils/auth.js';
  import { getExtWithDotFromFileName } from '@/utils/utils.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const message = useMessage();
  const loading = ref(false);
  const emit = defineEmits(['list-update']);
  const props = defineProps({
    batImportType: { // toExercises forData
      required: true,
      type: String
    },
    batImportFn: {
      required: true,
      type: Function
    }
  });

  const resReqMap = (() => {
    switch (props.batImportType) {
      case 'toExercises':
        return {
          file: {
            reqKey: 'Exercises[file]',
            default: null,
            rule: {
              key: 'file',
              required: true,
              validator: (rule, value) => {
                if (!!value) {
                  return true;
                } else {
                  return new Error('请选择文件');
                }
              }
            }
          },
          labelIds: {
            reqKey: 'Exercises[ids]',
            default: [],
            rule: {
              type: 'array',
              required: true,
              message: '必选',
              trigger: 'change'
            }
          }
        };
      case 'forData':
        return {
          file: {
            reqKey: 'PaperManual[file]',
            default: null,
            rule: {
              key: 'file',
              required: true,
              validator: (rule, value) => {
                if (!!value) {
                  return true;
                } else {
                  return new Error('请选择文件');
                }
              }
            }
          }
        };
    }
  })();

  const modalShow = ref(false);
  const openModal = () => {
    if (props.batImportType === 'toExercises') {
      updateLabels();  
    }
    modalShow.value = true;
  };
  const closeModal = () => {
    modalShow.value = false;
  };
  const clearModal = () => {
    Object.keys(resReqMap).forEach(key => {
      formValue[key] = resReqMap[key].default;
    });
  };
  const handleConfirm = () => {
    formRef.value.validate(errors => {
      if (!errors) {
        loading.value = true;
        const reqData = {};
        Object.keys(resReqMap).forEach(key => {
          switch (key) {
            case 'file':
              reqData[resReqMap[key].reqKey] = formValue[key].file;
              break;
            default:
              reqData[resReqMap[key].reqKey] = formValue[key];
          }
        });
        props.batImportFn(reqData).then(res => {
          if (res.code === SUCCESS) {
            closeModal();
            message.success('批量新增习题成功');
            switch (props.batImportType) {
              case 'toExercises':
                emit('list-update');
                break;
              case 'forData':
                emit('list-update', res.data);
                break;
            }
          } else {
            message.error('本次批量导入失败');
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
      }
    });
  };

  const labelOptions = ref([]);
  const updateLabels = () => {
    getLabelList({
      type: resourceEnum.EXERCISES_RESOURCE_TYPE,
      no_page: 1
    }).then(res => {
      if (res.code === SUCCESS) {
        labelOptions.value = res.data.list.map(item => ({
          label: `${ item.title }（${ item.file_num }）`,
          value: item.id
        }));
      }
    }).catch(err => {});
  };

  const formRef = ref(null);
  const formValue = (() => {
    const tempValue = {};
    Object.keys(resReqMap).map(key => {
      tempValue[key] = resReqMap[key].default;
    });
    return reactive(tempValue);
  })();
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqMap).map(key => {
      const rule = resReqMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();
  
  const triggerFileValidator = () => {
    formRef.value.validate(
      errors => {},
      rule => rule.key === 'file'
    );
  };

  const batImportTemplateUrl = (() => {
    return `${env.publicPath}file/${encodeURIComponent('批量新增习题')}.xls?v=${env.resourceVersion}`;
  })();

  const permitFileExtsWithDot = ['.xlsx', '.xls'];
  const uploadRef = ref(null);
  const handleClickUpload = () => {
    uploadRef.value.openOpenFileDialog();
  };
  const beforeUpload = ({ file }) => {
    const {
      name
    } = file.file;
    const extWithDot = getExtWithDotFromFileName(name);
    if (permitFileExtsWithDot.includes(extWithDot)) {
      return true;
    } else {
      message.error(`请选择 ${ permitFileExtsWithDot.join('、') } 格式文件`);
      return false;
    }
  };
  const handleChange = ({ fileList }) => {
    formValue.file = fileList[0];
    triggerFileValidator();
  };

  defineExpose({
    openModal
  });
</script>