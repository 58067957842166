<template>
  <div class="cc-items-box">
    <img
      v-if="scenesBg"
      :src="scenesBg"
      class="scenes-bg"
    />
    <NScrollbar style="height: 100%;">
      <NForm
        class="item-list-box"
        label-placement="left"
        label-width="100px"
        require-mark-placement="left"
        ref="formRef"
        :model="pageData"
      >
        <ul class="item-list">
          <li
            class="item"
            v-for="(item, index) in pageData.items"
            :key="item.key"
          >
            <ChatItem
              v-if="item.editBtnType === conversationEditBtnTypeVal.CHAT"
              :itemIndex="index"
              :itemData="item"
              @upload-click="handleUploadClick(index)"
              @del-click="handleDelClick(index)"
              @prev-click="handlePrevClick(index)"
              @next-click="handleNextClick(index)"
              @top-click="handleTopClick(index)"
              @bottom-click="handleBottomClick(index)"
            />
            <QuesItem
              v-else-if="item.editBtnType === conversationEditBtnTypeVal.QUES_ANS"
              :skillList="skillList"
              :itemIndex="index"
              :itemData="item"
              @del-click="handleDelClick(index)"
              @prev-click="handlePrevClick(index)"
              @next-click="handleNextClick(index)"
              @top-click="handleTopClick(index)"
              @bottom-click="handleBottomClick(index)"
            />
          </li>
        </ul>
      </NForm>
    </NScrollbar>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { NForm, NScrollbar } from 'naive-ui';

  import ChatItem from './ConversationItem/ChatItem.vue';
  import QuesItem from './ConversationItem/QuesItem.vue';

  import { listTypeTagVal } from '@/enumerators/study-situation.js'
  import { resStatusEnum } from '@/enumerators/http.js';
  import {
    conversationEditBtnTypeVal,
    conversationScenesValBg,
    generateConversationItem
  } from '../generate-data.js';

  // import { reqList } from '@/api/planning.js';

  const emit = defineEmits(['conversation-content-upload-click', 'conversation-item-del']);
  const props = defineProps({
    pageData: {
      type: Object,
      default: () => {}
    }
  });

  const { SUCCESS } = resStatusEnum;

  const scenesBg = computed(() => {
    const scenesType = props.pageData.scenesType;
    if (scenesType) {
      return conversationScenesValBg[scenesType];
    } else {
      return null;
    }
  });

  function addItem(editBtnType, optionVal) {
    props.pageData.items.push(generateConversationItem(
      'isCreate',
      {
        editBtnType: editBtnType,
        optionVal
      }
    ));
  }
  let triggerUploadItemIndex = -1;
  function handleUploadClick(index) {
    triggerUploadItemIndex = index;
    emit('conversation-content-upload-click');
  }
  function handleDelClick(index) {
    const items = props.pageData.items;
    const { savedID } = items[index];
    savedID && emit('conversation-item-del', { savedID });
    items.splice(index, 1);
  }
  function handlePrevClick(index) {
    if (index === 0) {
      return;
    }
    const arr = props.pageData.items;
    const item = arr.splice(index, 1)[0];
    arr.splice(index - 1, 0, item);
  }
  function handleNextClick(index) {
    const arr = props.pageData.items;
    if (arr.length - 1 === index) {
      return;
    }
    const item = arr.splice(index, 1)[0];
    arr.splice(index + 1, 0, item);
  }
  function handleTopClick(index) {
    if (index === 0) {
      return;
    }
    const arr = props.pageData.items;
    const item = arr.splice(index, 1)[0];
    arr.unshift(item);
  }
  function handleBottomClick(index) {
    const arr = props.pageData.items;
    if (arr.length - 1 === index) {
      return;
    }
    const item = arr.splice(index, 1)[0];
    arr.push(item);
  }

  const skillList = ref([]);
  /*
  function reqSkillList() {
    reqList({
      type: listTypeTagVal.SKILL,
      page: 1,
      page_size: 50000
    }).then(({ code, data }) => {
      const { list } = data;
      if (code === SUCCESS && Array.isArray(list)) {
        skillList.value = list.map(({ title, id, score }) => ({ label: title, value: id, score }));
      }
    });
  }
  reqSkillList();
  */

  const formRef = ref(null);

  defineExpose({
    addScenes(optionVal) {
      props.pageData.scenesType = optionVal;
    },
    addChat(optionVal) {
      addItem(conversationEditBtnTypeVal.CHAT, optionVal);
    },
    addQues(optionVal) {
      addItem(conversationEditBtnTypeVal.QUES_ANS, optionVal);
    },
    updateTalkFileData(data) {
      if (triggerUploadItemIndex !== -1) {
        Object.assign(props.pageData.items[triggerUploadItemIndex].talkFile, data);
        triggerUploadItemIndex = -1;
      }
    },
    validate() {
      const comp = formRef.value;
      comp && comp.validate();
    }
  });
</script>

<style lang="less" scoped>
  .cc-items-box {
    position: relative;
    height: calc(100vh - 296px);
  }

  .scenes-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .1;
  }

  .item-list-box {
    position: relative;
  }

  .item-list {
    margin: 0;
    padding: 0;

    &, & .item {
      list-style-type: none;
    }
  }

  .item {
    margin: 0 25px;
    padding-top: 20px;
    border-bottom: 1px dashed #ccc;

    &:last-child {
      border-bottom: none;
    }
  }
</style>