<template>
  <NFormItem label="能力分：" show-require-mark>
    <div>
      <NFormItem
        v-for="(item, index) in skills"
        :key="item.key"
        :rule="skillItemRules"
        :path="formItemValuePath ? `${formItemValuePath}.skills[${index}]` : `skills[${index}]`"
      >
        <NSelect
          style="width: 230px;"
          filterable
          :options="generateSelectOptions(item.skillID)"
          placeholder="请选择能力"
          v-model:value="item.skillID"

          disabled
        />
        <NInputNumber
          style="width: 120px; margin: 0 10px;"
          :show-button="false"
          clearable
          :precision="1"
          :min="0.1"
          placeholder="请输入分数"
          v-model:value="item.score"
          @blur="handleScoreBlur(item)"
        />
        <NButton
          v-show="skills.length > 1"
          type="error"
          text
          @click="delSkill(index)"
        >删除</NButton>
      </NFormItem>
      <!--
      <NButton type="primary" text @click="addSkill">添加能力</NButton>
      -->
    </div>
  </NFormItem>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { NFormItem, NSelect, NInputNumber, NButton } from 'naive-ui';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { listTypeTagVal } from '@/enumerators/study-situation.js'

  import { generateSkillItem } from '../../generate-data.js';

  import { reqList } from '@/api/planning.js';

  const props = defineProps({
    skills: {
      required: true,
      type: Array
    },
    formItemValuePath: {
      type: String,
      default: ''
    },
    autoInitSkillList: {
      type: Boolean,
      default: true
    },
    initSkillList: {
      type: Array,
      default: () => []
    }
  });

  const { SUCCESS } = resStatusEnum;

  const skillList = ref([]);
  const skillListShow = computed(() => {
    return props.autoInitSkillList ? skillList.value : props.initSkillList;
  });
  const selectedSkillIDs = computed(() => {
    const list = [];
    props.skills.forEach(({ skillID }) => {
      skillID && list.push(skillID);
    });
    return list;
  });
  function generateSelectOptions(currentSelectedSkillID) {
    const selectedSkillIDsLink = selectedSkillIDs.value;
    const skillListShowLink = skillListShow.value;
    return skillListShowLink.map(item => {
      const disabled = selectedSkillIDsLink.includes(item.value) && item.value !== currentSelectedSkillID;
      return {
        ...item,
        disabled
      }
    });
  }
  function reqSkillList() {
    reqList({
      type: listTypeTagVal.SKILL,
      page: 1,
      page_size: 50000
    }).then(({ code, data }) => {
      const { list } = data;
      if (code === SUCCESS && Array.isArray(list)) {
        skillList.value = list.map(({ title, id }) => ({ label: title, value: id }));
      }
    });
  }
  props.autoInitSkillList && reqSkillList();

  const skillItemRules = {
    validator(rule, { skillID, score }) {
      if (!skillID) {
        return new Error('请选择能力');
      }
      if (!score) {
        return new Error('请输入分数');
      }
      return true;
    },
    trigger: ['blur', 'change']
  };
  function handleScoreBlur(obj) {
    let score = obj.score;
    if (score) {
      score = Math.round(score * 10) / 10;
      obj.score = score;
    }
  }
  function delSkill(index) {
    props.skills.splice(index, 1);
  }
  function addSkill() {
    props.skills.push(generateSkillItem('isCreate'));
  }
</script>