<template>
  <NFormItem
    ref="formItemRef"
    class="chat-item-box"
    :rule="chatItemRules"
    :path="`items[${itemIndex}]`"
  >
    <div class="chat-item">
      <div class="serial-no primary-color">{{ itemIndex + 1 }}、</div>
      <div class="chat-item-left">
        <img :src="conversationPeopleValAvatar[itemData.optionVal]" />
        <div class="name">{{ conversationPeopleValName[itemData.optionVal] }}</div>
      </div>
      <div class="chat-item-center">
        <WangEditor5
          :toolbarConfig="editorToolbarConfig"
          :editorConfig="editorConfig"
          v-model:htmlValue="itemData.talkTxt"
          style="font-size: 22px;"
        />
        <!--
        <div class="talk-file-box">
          对话音频：
          <div class="upload-btn-box">
            <NButton type="primary" ghost @click="emit('upload-click')">上传文件</NButton>
            <div class="upload-tips">支持文件格式：.mp3，大小不能超过100MB</div>
            <div
              v-if="itemData.talkFile.fileUrl"
              class="talk-file-prelisten"
            >
              <audio :src="itemData.talkFile.fileUrl" controls controlsList="nodownload"></audio>
            </div>
          </div>
        </div>
        -->
      </div>
      <div class="chat-item-right">
        <NButton type="error" size="small" ghost @click="emit('del-click')">删除</NButton>
        <NButton type="success" size="small" ghost @click="emit('prev-click')">上移</NButton>
        <NButton type="success" size="small" ghost @click="emit('next-click')">下移</NButton>
        <NButton type="warning" size="small" ghost @click="emit('top-click')">最前</NButton>
        <NButton type="warning" size="small" ghost @click="emit('bottom-click')">最后</NButton>
      </div>
    </div>
  </NFormItem>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { NFormItem, NButton } from 'naive-ui';

  import WangEditor5 from '@/components/WangEditor5/WangEditor5.vue';

  import {
    conversationPeopleValAvatar,
    conversationPeopleValName
  } from '../../generate-data.js';

  const emit = defineEmits(['upload-click', 'del-click', 'prev-click', 'next-click', 'top-click', 'bottom-click']);
  const props = defineProps({
    itemIndex: {
      required: true,
      type: Number
    },
    itemData: {
      required: true,
      type: Object
    }
  });

  const editorToolbarConfig = {
    excludeKeys: [
      'group-video',
      'emotion',
      'blockquote',
      'code',
      'color',
      'bgColor',
      'todo',
      'insertLink',
      'group-image',
      'insertTable',
      'codeBlock',
      'fullScreen'
    ]
  };
  const editorConfig = {
    placeholder: '请输入内容...',
    hoverbarKeys: {
      text: {
        menuKeys: [
          'headerSelect',
          'bulletedList',
          '|',
          'bold',
          'through',
          'clearStyle'
        ]
      },
    }
  };

  const chatItemRules = {
    key: 'chatItemRules',
    validator(rule, { talkTxt, talkFile }) {
      if (!talkTxt) {
        return new Error('请输入对话内容');
      }
      /*
      if (!talkFile || !talkFile.fileUrl) {
        return new Error('请上传对话音频文件');
      }
      */
      return true;
    },
    trigger: ['blur']
  };

  const formItemRef = ref(null);
  /*
  watch(
    () => props.itemData.talkFile.fileUrl,
    () => {
      formItemRef.value.validate({
        trigger: 'blur'
      });
    }
  );
  */
  watch(
    () => props.itemData.talkTxt,
    () => {
      formItemRef.value.validate({
        trigger: 'blur'
      });
    }
  );
</script>

<style lang="less" scoped>
  .chat-item-box {
    padding-bottom: 10px;

    :deep(.n-form-item-feedback-wrapper) {
      min-height: 0;
      margin-left: 112px;
    }
  }

  .chat-item {
    position: relative;
    width: 100%;
  }

  .serial-no {
    position: absolute;
    left: -15px;
    top: 0;
    z-index: 1;
  }

  .chat-item-left {
    position: absolute;
    left: 10px;
    top: 0;

    img {
      width: 95px;
      height: 100px;
      object-fit: contain;
    }

    .name {
      text-align: center;
    }
  }

  .chat-item-center {
    margin: 0 67px 0 115px;
  }
  
  .talk-file-box {
    line-height: 33px;
    margin-top: 15px;
  }
  .upload-btn-box {
    display: inline-block;
    vertical-align: top;
  }
  .upload-tips {
    color: #ccc;
  }
  .talk-file-prelisten {
    audio {
      vertical-align: top;
    }
  }

  .chat-item-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;

    :deep(.n-button) {
      margin-bottom: 4px;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
</style>