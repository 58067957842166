<template>
  <div class="fi-comment">
    <NPopover
      placement="bottom"
      trigger="click"
      :to="false"
    >
      <template #trigger>
        <!-- <div class="ques-mark">?</div> -->
        <!-- <svg class="hand-icon" :class="{ preview: isPreview }" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M895.783519 383.907222a125.409693 125.409693 0 0 0-62.704846 17.062544A127.969074 127.969074 0 0 0 725.158087 341.250864a125.409693 125.409693 0 0 0-62.704847 17.062544A127.969074 127.969074 0 0 0 511.876297 306.272651V127.969074a127.969074 127.969074 0 0 0-255.938149 0v409.074474l-28.57976-29.006324A131.808146 131.808146 0 0 0 133.087837 469.219939 133.087837 133.087837 0 0 0 0 602.307776 236.316224 236.316224 0 0 0 69.1033 767.814445l151.856635 151.430071 39.670413 79.340826A42.656358 42.656358 0 0 0 298.594506 1023.752593a39.670413 39.670413 0 0 0 19.195361-4.692199 42.656358 42.656358 0 0 0 18.768798-57.15952l-42.656358-85.312716a46.921994 46.921994 0 0 0-7.678145-11.090653L127.969074 708.948671a149.297253 149.297253 0 0 1-42.656358-106.640895 47.775121 47.775121 0 0 1 81.473644-33.698523l101.522132 101.522132a42.656358 42.656358 0 0 0 46.49543 8.957835A42.656358 42.656358 0 0 0 341.250864 639.845371V127.969074a42.656358 42.656358 0 0 1 85.312716 0v469.219939a42.656358 42.656358 0 0 0 85.312717 0v-170.625433a42.656358 42.656358 0 0 1 85.312716 0v170.625433a42.656358 42.656358 0 0 0 85.312716 0v-127.969074a42.656358 42.656358 0 0 1 85.312716 0v127.969074a42.656358 42.656358 0 0 0 85.312716 0v-85.312716a42.656358 42.656358 0 0 1 85.312716 0v263.189729a246.980313 246.980313 0 0 1-72.942372 175.744195 42.656358 42.656358 0 0 0 0 60.572028 42.656358 42.656358 0 0 0 60.572028 0A331.866466 331.866466 0 0 0 1023.752593 775.066026V511.876297a127.969074 127.969074 0 0 0-127.969074-127.969075z"></path></svg> -->
        <img src="../../../../../../public/img/knowledge/i-hand-left-top.png" class="hand-icon" :class="{ preview: isPreview }" />
      </template>
      <div class="fi-comment-txt" v-html="commentHTML"></div>
    </NPopover>
    <slot name="btns"></slot>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { NPopover } from 'naive-ui';

  const props = defineProps({
    isPreview: {
      type: Boolean,
      default: false
    },
    itemData: {
      require: true,
      type: Object
    }
  });

  const commentHTML = computed(() => {
    return props.itemData.answer.replace(/\n/g, '<br />')
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .fi-comment {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /*
    line-height: 13px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: @primary-color;
    border: 2px solid @primary-color;
    border-radius: 50%;
    */
    user-select: none;
    cursor: default;

    &:hover {
      :deep(.fi-btns) {
        display: block;
      }
    }
  }

  .hand-icon {
    width: 100%;
    height: 100%;
    fill: currentColor;
    color: @warning-color;
    outline: none;

    &.preview {
      animation-name: bounce-left-top;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
    }
  }

  .fi-comment-txt {
    width: 350px;
    line-height: 16px;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
  }
</style>